<template>
    <el-dialog
        title="提示"
        class="my-custom-dialog post-repeat-dialog"
        :visible.sync="visible"
        width="450px"
        :show-close="false"
        :append-to-body="true"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="handleClose"
    >
        <div class="my-custom-header">
            <div class="title">转发到我的首页</div>
            <div :class="['close-icon', mouseActionClass]" @click="handleClose" @mouseenter="handleCloseMouseEnter" @mouseleave="handleCloseMouseLeave">
                <loginClose />
            </div>
        </div>
        <div class="my-custom-body">
            <!-- <el-input type="textarea" v-model="formData.content" placeholder="请输入信息" resize="none" :autosize="{ minRows: 3 }"></el-input> -->
            <div ref="my-editor" class="my-editor medium-editor-element" contenteditable="true" spellcheck="false" data-medium-editor-element="true" role="textbox" aria-multiline="true" data-medium-editor-editor-index="1" medium-editor-index="1cfb372c-807a-5edb-cd72-58e6c1589bd3">
                
            </div>
        </div>
        <div class="my-custom-footer">
            <el-button class="cancel" @click="handleClose">取消</el-button>
            <el-button class="submit" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { loginClose } from '../../core/icon';
    export default {
        name: 'PostRepeatDialog',
        props: {
            dialogVisible: {
                type: Boolean,
                default: false
            }
        },
        components: {
            loginClose,
        },
        data(){
            return {
                mouseActionClass: '',
                formData: {
                    content: '',
                },
            }
        },
        created () {
            // console.log('created, PostRepeatDialog');
        },
        computed: {
            visible: {
                set: function(){

                },
                get: function(){
                    return this.dialogVisible;
                }
            }
        },
        watch: {
            dialogVisible: function(val) {
                if (!val){
                    // console.log('watch, dialogVisible');
                    this.$refs['my-editor'].innerHTML = '';
                }
            }
        },
        methods: {
            handleLogin(){
                // console.log('login');
                this.handleValidate();
                if (!this.valid){
                    return this.$message('请补全信息');
                }
                if (this.active !== 'account_password'){
                    return this.$message('暂不支持验证码登录');
                }
                //   /api/user/login
                this.api.postFormAPISM(
                    this.active === 'account_password' ?
                        {
                            username: this.formData.username,
                            password: this.formData.password
                        } :
                        {

                        }
                    ,
                    '/user/login'
                ).then(res => {
                    if (res.data && res.data.hasOwnProperty('data') && res.data.data.hasOwnProperty('token') && res.data.data.success){
                        localStorage.setItem('token', res.data.data.token);
                        this.$store.commit('setToken', res.data.data.token);
                        this.$store.dispatch('getUserInfo');
                        this.handleClose();
                    } else {
                        try {
                            // console.log('success: false', res.data.data);
                            this.$message({
                                type: 'error',
                                message: res.data.data.msg || res.data.data.message || '密码错误',
                                // duration: 0,
                            });
                        } catch(error) {
                            // console.log('catch, error', error);
                            this.$message({
                                type: 'error',
                                message: '密码错误',
                                // duration: 0,
                            });
                        }
                        
                    }
                }).catch(error => {
                    console.log('error', error);
                })
            },
            handleSubmit(){
                // console.log('handleSubmit', this.$refs['my-editor'].innerHTML);
                this.$emit('handleSubmit', this.$refs['my-editor'].innerHTML);
            },
            handleClose(){
                // console.log('handleClose. login, innner', this.$data, this.$options.data());
                this.$emit('handleClose' );
                this.mouseActionClass = '';
                this.mainActive = '';
                this.formData = {
                    ...this.$options.data().formData,
                }
                
            },
            handleCloseMouseEnter(){
                // console.log('handleCloseMouseEnter');
                this.mouseActionClass = 'active';
            },
            handleCloseMouseLeave(){
                // console.log('handleCloseMouseLeave');
                this.mouseActionClass = '';
            }
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>