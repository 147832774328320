<template>
    <div id="comment-editor">
        <div class="logo-editor">
            <!-- <img class="logo" src="../../assets/images/userLogo.jpg" alt=""> -->
            <userLogoComponent width="32" height="32" :user="userInfo" :disabled-hover="true" />
            <div class="editor">
                <div class="triangle">
                    <commentEditorTriangle />
                </div>
                <!-- <v-md-editor
                    v-model="text" height="100px" mode="edit"
                    left-toolbar=""
                    right-toolbar=""
                >
                </v-md-editor> -->
                <Editor
                    ref="tinymce" v-model="text"
                    :id="id" :init="init" :disabled="disabled"
                    placeholder="请输入评论"
                />
            </div>
        </div>
        <div class="emote-btn">
            <!-- <div class="emote">
                <emote />
            </div>
            <div class="upload-image">
                <uploadImage />
            </div> -->
            <div class="btn">
                <span class="cancel" @click="handleCancel">取消</span>
                <span class="publish" @click="handlePublish">发布</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { emote, uploadImage, reply, commentEditorTriangle } from '../../core/icon';
    import userLogoComponent from '../UserLogo/index.vue';
    import Editor from '@tinymce/tinymce-vue'//编辑器引入
    export default {
        name: 'CommentEditor',
        props: {
            type:{
                type: String,
                default: 'post'
            },
            tinymceId: {
                type: Number,
                default: 0,
            }
        },
        components: {
            emote,
            uploadImage,
            reply,
            commentEditorTriangle,
            userLogoComponent,

            Editor,
        },
        data(){
            return {
                text: '',
                // tinymceId: '11',
                disabled: false,
                init: {
                    selector: '#tinymce',
                    language_url: './tinymce/langs/zh_CN.js',//汉化路径是自定义的，一般放在public或static里面
                    language: 'zh_CN',
                    skin_url: './tinymce/skins/ui/oxide',
                    content_css: `./tinymce/skins/content/default/content.css`,
                    plugins: [
                        'emoticons'
                        // 'link', 'emoticons', 'preview',
                        // 'image',
                    ],//插件
                    //工具栏
                    menubar: false, //隐藏菜单栏
                    // image
                    toolbar: 'emoticons', //字体大小
                    height: 500,//高度
                    placeholder: '在这里输入文字',
                    branding: false,//隐藏右下角技术支持
                    paste_preprocess: function (plugin, args) {
                        // console.log(args.content);
                    },
                    paste_as_text: true,
                    //init_instance_callback为回调配置项
                    init_instance_callback: (editor) => {
                        // console.log(`回调----`)
                        editor.on('input', e => {
                            // console.log('文本框input触发')
                            this.$emit('input', e.target.innerHTML)
                        });
                        editor.on('change', e => {
                            // console.log('文本框change触发')
                            this.$emit('change', e.level.content)
                        })
                    },
                    // images_upload_url: '',
                    // // image 图片上传自定义   目前转base64
                    // images_upload_handler: function (blobInfo, succFun, failFun) {
                    //     // console.log('images_upload_handler, blobInfo', blobInfo);
                    //     // console.log('base64', blobInfo.base64());
                    //     succFun('data:image/png;base64,' + blobInfo.base64());
                    // }
                }
            }
        },
        computed: {
            userInfo(){
                return this.$store.state.userInfo;
            },
            id(){
                return this.tinymceId ? ( 'comment_id' + this.tinymceId) : '11'
            }
        },
        methods: {
            handleClear(){
                this.text = '';
            },
            handleCancel(){
                this.type === 'comment' && this.$emit('handleCancel');
            },
            handlePublish(){
                // console.log('handlePublish', this.text.replace(/(^<p>|(<\/p>$))/g, ''));
                // return;
                if (!this.text){
                    return this.$message({
                        type: 'warning',
                        message: '请输入评论信息'
                    });
                }
                this.$emit('handlePublish', this.text.replace(/(^<p>|(<\/p>$))/g, ''));
            }
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>