<template>
    <div id="user-logo-component" :style="{ width: width + 'px', height: height + 'px' }" @mouseenter.stop="handleMouseEnter" @mouseleave.stop="handleMouseLeave">
        <img :src="src" :class="disabledHover ? 'disabled' : ''">
        <div v-if="visible" :class="['my-custom-user-popover', position]">
            <div class="info-wrapper">
                <div class="info">
                    <div class="nickname">{{ userInfo.nickname || '&nbsp;' }}</div>
                    <div class="follow-fan">
                        <span>
                            <span class="value">{{ userInfo.fans_count || 0 }}</span>
                            粉丝
                        </span>
                        <div class="split"></div>
                        <span>
                            <span class="value">{{ userInfo.follow_count || 0 }}</span>
                            关注
                        </span>
                    </div>
                </div>
                <userLogoComponent width="48" height="48" :user="{ avatar: userInfo.avatar }" :disabled-hover="true"  />
            </div>
            
            <div v-if="userInfo.id" :class="['follow', userInfo.is_follow? 'followed' : 'un-follow']" @click.stop="handleFollow">
                <template v-if="!userInfo.is_follow">
                    <add />
                    关注
                </template>
                <template v-else>
                    <checkFollow />
                    已关注
                </template>
            </div>
            <!-- <div class="arrow"></div> -->
        </div>
    </div>
</template>

<script>
    import { completeUserLogoUrl } from '../../utils/util';
    import { add, checkFollow } from '../../core/icon';
    export default {
        name: 'userLogoComponent',
        props: {
            width: {
                type: String,
                default: '',
            },
            height: {
                type: String,
                default: '',
            },
            user: {
                type: Object,
                default: () => {
                    return {
                        id: '',
                        avatar: '',
                    }
                }
            },
            disabledHover: {
                type: Boolean,
                default: false,
            },
            position: {
                type: String,
                default: 'top',
            }
        },
        components: {
            add,
            checkFollow,
        },
        data () {
            return {
                visible: false,
                userInfo: {
                    id: '',
                },
                timeout: null,
            }
        },
        activated () {
            // console.log('activated, userLogoComponent');
        },
        computed: {
            src(){
                return completeUserLogoUrl(this.user.avatar);
            },
            token(){
                return this.$store.state.token;
            }
        },
        methods: {
            handleMouseEnter(){
                // console.log('handleMouseEnter', this.disabledHover);
                if (this.timeout) window.clearTimeout(this.timeout);
                if (this.disabledHover || this.visible) return;
                if (!this.user.id){
                    // console.log('handleMouseEnter', this.user);
                    return;
                };
                this.visible = true;
                this.loading = true;
                this.getUserInfoById();
            },
            getUserInfoById(){
                
                // /api/user/getuserinfobyid
                this.api.postFormAPISM(
                    {
                        user_id: this.user.id
                    },
                    '/user/getuserinfobyid'
                ).then(res => {
                    
                    let { user, follow_count, fans_count, user_follow_ship } = res.data.data;
                    this.userInfo = {
                        ...user,
                        follow_count,
                        fans_count,
                        ...user_follow_ship
                    };
                    // console.log('getUserInfoById', this.userInfo);
                    this.loading = false;
                }).catch(error => {
                    console.log('error', error);
                })
            },
            async handleFollow(){
                if (!this.token){
                    this.$message({
                        type: 'warning',
                        message: '请先登录'
                    });
                    return this.$login.show();
                }
                if (this.userInfo.id == this.$store.state.userInfo.id){
                    return this.$message({
                        type: 'info',
                        message: '不能关注自己'
                    });
                }
                let validatePromise = new Promise(resolve => {
                    if (this.userInfo.is_follow === false){
                        resolve(true);
                        return;
                    }
                    this.$confirm("确定取消关注吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        confirmButtonClass: "butText",
                        type: "info",
                        customClass: 'my-custom-confirm',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                    }).then(() => {
                        // console.log('confirm');
                        resolve(true);
                    }).catch(error => {
                        resolve(false);
                    })
                })
                let valid = await validatePromise;
                if (!valid) return;
                // /api/user/follow
                this.api.postFormAPISM(
                    {
                        target_user_id: this.userInfo.id,
                        is_follow: !this.userInfo.is_follow,
                        remark_name: '',
                    },
                    '/user/follow'
                ).then(res => {
                    // console.log('handleFollow', res.data);
                    this.getUserInfoById();
                }).catch(error => {
                    console.log('error', error);
                })
            },
            handleMouseLeave(){
                this.timeout = setTimeout(() => {
                    this.visible = false;
                }, 500)
            }

        }
    }
</script>
<style lang="less">
    #user-logo-component{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        flex-shrink: 0;
        &>img{
            width: 100%;
            height: 100%;
            min-width: 20px;
            min-height: 20px;
            border-radius: 50%;
            &:not(.disabled){
                cursor: pointer;
            }
        }
        &>.my-custom-user-popover{
            position: absolute;
            width: 300px;
            height: 150px;
            
            padding: 15px 20px 20px;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
            background-color: #fff;

            color: #666c72;
            font-size: 15px;
            z-index: 999;
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: space-between;
            top: -160px;
            &.bottom{
                top: 55px;
            }
            &>.info-wrapper{
                display: flex;
                border-bottom: 1px solid rgb(217, 217, 217);
                padding: 0 0 12px;
                &>.info{
                    flex-grow: 1;
                    display: flex;
                    flex-flow: column nowrap;
                    
                    
                    &>.nickname{
                        font-size: 15px;
                        color: #33353c;
                        font-weight: bold;

                    }
                    &>.follow-fan{
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #909499;
                        padding: 8px 0;
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        &>span{
                            &>.value{
                                padding: 0 2px 0 0;
                            }
                        }
                        &>.split{
                            width: 1px;
                            height: 68%;
                            margin: 0 6px;
                            background-color: rgb(217, 217, 217);
                        }

                    }
                }
            }
            &>.follow{
                width: 108px;
                height: 32px;
                font-size: 14px;
                color: #fff;
                background-color: #058e44;
                cursor: pointer;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
                &.followed{
                    background-color: #d1ebff;
                    color: #058e44;
                    &>svg{
                        &>path{
                            fill: #058e44;
                        }
                    }
                }
                &>svg{
                    width: 16px;
                    height: 16px;
                    margin: 0 4px 0 0;
                }
                
            }
        }
    }
</style>